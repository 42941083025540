import {React, useState, createContext} from 'react'

export const SearchMenuContext = createContext('');

/**
 * The SearchMenuProvider component is a context provider that allows its children components to access
 * and use the search menu data.
 * @returns The SearchMenuProvider component is being returned.
 */
const SearchMenuProvider = ({children}) => {
    const [SearchMenu, setSearchMenu] = useState({
        companyPlaceholder :"Search for companies",
        investorPlaceholder :"Search for investors",
        ladvisorPlaceholder :"Search for legal advisors",
        tadvisorPlaceholder :"Search for transaction advisors",
        cfscompanyPlaceholder:"Search for companies",
        acquirerPlaceHolder :"Search for acquirers",
        data:[]
    }
    );

    const [header, setHeader] = useState([
        {accessor: "pe_company", label: "PE Company"},
        {accessor:"re_company", label:"RE Company"},
        {accessor:"cfs_company", label:"Financials"},

        {accessor: "pe_investor", label: "PE Investor"},
        {accessor:"re_investor", label:"RE Investor"},
        {accessor: "pe_fund", label:"PE Funds"},
        {accessor:"pe_acquirer", label:"PE Acquirer"},
        {accessor:"pe_advisor", label:"PE Advisor"},
        {accessor:"re_advisor", label:"RE Advisor"},

        {accessor: "pe_investment", label: "PE Investments"},
        {accessor: "pe_exit", label: "PE Exits"},
        {accessor: "pe_ipo", label: "PE Backed IPO"},
        {accessor: "angel", label: "Angel"},
        {accessor: "incubation", label: "Incubation"},
        {accessor:"merger_acquisition", label:"M&A"},
        {accessor:"re_investments", label:"RE Investments"},
        {accessor:"re_exit", label:"RE Exit"},
        {accessor:"re_ipo", label:"RE IPO"},
        
    ])
    
    const pe_investment = {accessor: "pe_investment", label: "PE-VC Investments"}
    const pe_exit = {accessor: "pe_exit", label: "PE-VC Exits"}
    const pe_ipo = {accessor: "pe_ipo", label: "PE-VC Backed IPOs"}
    const angel = {accessor: "angel", label: "Angel Investments"}
    const incubation = {accessor: "incubation", label: "Incubation"}
    const ma = {accessor:"merger_acquisition", label:"M&A Deals"}
    const re_investment = {accessor:"re_investment", label:"PE-RE Investments"}
    const re_exit = {accessor:"re_exit", label:"PE-RE Exits"}
    const re_ipo = {accessor:"re_ipo", label:"PE-RE Backed IPOs"}
    const cfs = {accessor:"cfs_company", label:"Private Company Financials (CFS)"}
    const pe_investor = {accessor: "pe_investor", label: "PE-VC Investors"}
    const angel_investor = {accessor: "angel_investor", label: "Angel Investors"}
    const re_investor = {accessor:"re_investor", label:"PE-RE Investors"}
    const pe_fund = {accessor: "pe_fund", label:"PE-VC Fund Raising"}
    const re_fund = {accessor: "re_fund", label:"PE-RE Fund Raising"}
    const pe_acquirer = {accessor:"pe_acquirer", label:"Acquirer (M&A)"}
    const pe_advisor = {accessor:"pe_advisor", label:"To PE-VC / M&A Transactions"}
    const re_advisor = {accessor:"re_advisor", label:"To  PE-RE Transactions"}

    const staticHeaders = {
    //   "Company Profiles (Transacted)" : [
    //     {accessor: "pe_company", label: "PE-VC Backed Company"},
    //     {accessor:"re_company", label:"PE-RE Backed Company"},
    //     {accessor:"cfs_company", label:"Financials"}
    // ],
      "Deals" : [
        pe_investment,
        pe_exit,
        pe_ipo,
        angel,
        incubation,
        ma,
        re_investment,
        re_exit,
        re_ipo,
    ],
    "Company Financials" : [cfs],
    "Investors / Acquirers" : [
        pe_investor,
        angel_investor,
        re_investor,
        pe_fund,
        re_fund,
        pe_acquirer
    ],
    "Advisors" : [
        pe_advisor,
        re_advisor
    ]};
    
    const peStaticHeaders = {
        "Deals": [
            pe_investment,
            pe_exit,
            pe_ipo,
            angel,
            incubation,
        ],
        "Investors  / Acquirers": [
            pe_investor,
            angel_investor,
            pe_fund,
        ],
        "Advisors" : [
            pe_advisor
        ],
        "More Results From Other Databases": [
            ma,
            pe_acquirer,
            re_investment,
            re_exit,
            re_ipo,
            re_investor,
            re_fund,
            re_advisor,
            cfs
        ]
    };
    
    const vcStaticHeaders = {
        "Deals": [
            pe_investment,
            pe_exit,
            pe_ipo,
            angel,
            incubation,
        ],
        "Investors  / Acquirers": [
            pe_investor,
            angel_investor,
            pe_fund,
        ],
        "Advisors" : [
            pe_advisor
        ],
        "More Results From Other Databases": [
            ma,
            pe_acquirer,
            re_investment,
            re_exit,
            re_ipo,
            re_investor,
            re_fund,
            re_advisor,
            cfs
        ]
    };
    const maStaticHeaders = {
        "Deals": [
            ma
        ],
        "Acquirers": [
            pe_acquirer,
        ],
        "More Results From Other Databases": [
            pe_investment,
            pe_exit,
            pe_ipo,
            angel,
            incubation,
            pe_investor,
            angel_investor,
            pe_fund,
            pe_advisor,
            re_investment,
            re_exit,
            re_ipo,
            re_investor,
            re_fund,
            re_advisor,
            cfs
        ]
    };

    const reStaticHeaders = {
        "Deals": [
            re_investment,
            re_exit,
            re_ipo,
        ],
        "Investors": [
            re_investor,
            re_fund,
        ],
        "Advisors" : [
            re_advisor
        ],
        "More Results From Other Databases": [
            pe_investment,
            pe_exit,
            pe_ipo,
            angel,
            incubation,
            pe_investor,
            angel_investor,
            pe_fund,
            pe_advisor,
            ma,
            pe_acquirer,
            cfs
        ]
    };
    const cfsStaticHeaders = {
        "Company Financials" : [cfs],
        "More Results From Other Databases": [
            pe_investment,
            pe_exit,
            pe_ipo,
            angel,
            incubation,
            pe_investor,
            angel_investor,
            pe_fund,
            pe_advisor,
            ma,
            pe_acquirer,
            re_investment,
            re_exit,
            re_ipo,
            re_investor,
            re_fund,
            re_advisor
        ],
        
    };

    const searchParameters = {SearchMenu, header, staticHeaders, peStaticHeaders, vcStaticHeaders, reStaticHeaders, maStaticHeaders, cfsStaticHeaders};

    return (
        <SearchMenuContext.Provider value={searchParameters}>
            {children}
        </SearchMenuContext.Provider>
    )
}

export default SearchMenuProvider
